<template>

<div>

	<com-title text="Library" />

	<template v-if="$convention">

		<com-item v-if="$permissions.library.games.enabled" route="Convention.Library.Games" text="Games" :count="$organisation.tracking.library.games" />

		<com-divide />
		
		<com-external :url="libraryLink" iconAfter="external" text="Open Library" />

	</template>

	<template v-if="$organisation && !$convention">

		<com-item route="Organisation.Library.Games" text="Games" :count="$organisation.tracking.library.games" />
		<com-item route="Organisation.Library.Settings" text="Settings" />
		<com-divide />
		<com-external :url="libraryLink" iconAfter="external" text="Open Library" />

	</template>

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-divide': () => import('./../../panel/common/Divide'),
		'com-item': () => import('./../../panel/common/Item'),
		'com-external': () => import('./../../panel/common/External')
	},

	computed: {

		libraryLink: function() {

			if (this.$convention && this.$permissions.library.rented) {

				return process.env.VUE_APP_LIBRARY + '/' + this.$organisation.slug + '/' + this.$convention.slug

			} else {

				return (this.$organisation.settings.library.external.enabled) ? this.$organisation.settings.library.external.url : process.env.VUE_APP_LIBRARY + '/' + this.$organisation.slug

			}

			
		}

	}

}

</script>

<style scoped>

</style>
